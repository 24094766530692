module.exports = [{
      plugin: require('../../../themes/gatsby-theme-kn/gatsby-browser.js'),
      options: {"plugins":[],"storyblokOptions":{"storyblokToken":"2b7Ck5qKTZhnI3cqAy0GNQtt","storyblokVersion":"published","storyblokHomeSlug":"home","resolveRelations":["similar_product_relations"]},"includedPageTypes":["article_page","inspiration_page","product_page","email_abbinder_page","home_page","event_landing_page"],"includedCollectionTypes":["index_page"],"clientPages":[{"full_slug":"de/haendlersuche","title":"Partnersuche"},{"full_slug":"en/dealers","title":"Dealer Search"},{"full_slug":"fr/dealers","title":"Recherche de revendeurs"},{"full_slug":"nl/dealers","title":"Dealer zoeken"}],"productCategories":["product_category_page"]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"König + Neurath","short_name":"K+N","start_url":"/","background_color":"#ffffff","theme_color":"#ff6e3d","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f4e78143752fa9d29c83cdb635436e9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
